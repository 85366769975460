import React from "react";
import { FaTimesCircle } from "react-icons/fa";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import * as modalCloseStyles from "./modal-close.module.css";

const ModalClose = () => (
  <div className="row">
    <div className={`row-container ${modalCloseStyles.closeRowContainer}`}>
      <Link to="/" title="Close">
        <FaTimesCircle />
      </Link>
    </div>
  </div>
);

export default ModalClose;
