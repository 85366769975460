import * as React from "react";
import { useLocalization } from "gatsby-theme-i18n";
import Seo from "../components/seo";
import VideoWrapper from "../components/video-wrapper";
import ModalClose from "../components/modal-close";

const VideoPage = () => {
  const { locale, config } = useLocalization();
  const { langDir, localName } = config.find((item) => item.code === locale);
  const videoHost = "https://api.arclight.org/videoPlayerUrl?refId=";
  const i18nRefIds = {
    en: "1_529-jf-0-0",
    fa: "1_6788-jf-0-0",
    tk: "1_420-jf-0-0",
    tr: "1_1942-jf-0-0",
    uz: "1_3888-jf-0-0",
  };
  const turkmenYoutube =
    "https://www.youtube.com/embed/videoseries?list=PLrfZmr0ECoGe0jJpXwX6Jdd2w1YXeLMZK";
  const videoUrl =
    locale !== "tk" ? `${videoHost}${i18nRefIds[locale]}` : turkmenYoutube;
  return (
    <main>
      <Seo title={`Video - ${localName}`}>
        <html lang={locale} dir={langDir} />
      </Seo>
      <ModalClose />
      <VideoWrapper videoUrl={videoUrl} />
    </main>
  );
};

export default VideoPage;
