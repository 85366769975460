import React from "react";
import * as videoStyles from "./video-wrapper.module.css";

const VideoWrapper = ({ videoUrl }) => (
  <div className="row">
    <div className={`row-container ${videoStyles.videoRowContainer}`}>
      <div className="columns">
        <div className={videoStyles.videoWrapper}>
          <iframe title="Jesus Film" src={videoUrl} allow="fullscreen" />
        </div>
      </div>
    </div>
  </div>
);

export default VideoWrapper;
